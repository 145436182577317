/* This stylesheet generated by Transfonter (https://transfonter.org) on February 25, 2018 4:05 PM */

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-Regular.eot');
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
        url('/assets/fonts/ubuntu/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-Regular.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-Light.eot');
    src: local('Ubuntu Light'), local('Ubuntu-Light'),
        url('/assets/fonts/ubuntu/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-Light.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-LightItalic.eot');
    src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'),
        url('/assets/fonts/ubuntu/Ubuntu-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-LightItalic.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-Medium.eot');
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
        url('/assets/fonts/ubuntu/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-Medium.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-Italic.eot');
    src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
        url('/assets/fonts/ubuntu/Ubuntu-Italic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-Italic.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-Bold.eot');
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
        url('/assets/fonts/ubuntu/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-Bold.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-BoldItalic.eot');
    src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
        url('/assets/fonts/ubuntu/Ubuntu-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-BoldItalic.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/ubuntu/Ubuntu-MediumItalic.eot');
    src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'),
        url('/assets/fonts/ubuntu/Ubuntu-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ubuntu/Ubuntu-MediumItalic.woff') format('woff'),
        url('/assets/fonts/ubuntu/Ubuntu-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
